import React, { Component } from 'react';
import { Header, Segment, Icon, Checkbox, Message, Button, List, Modal } from 'semantic-ui-react';

import { isManualReviewNeeded } from 'util/ManualReview.js';
import { extractStatus, isTaxReported } from 'util/FactMapUtil';
import { triggerPrint } from 'util/print/triggerPrint';
import { isAdmin, isAkademi } from 'util/userMethods';
import withUserData from 'util/withUserData';
import ColoredText from 'design/atoms/ColoredText';
import { statusesMap } from 'design/molecules/AppLauncher/userStatuses';

import errorMessagesMap from './errorMessagesFromErst';
import styles from './index.module.scss';
import { ADMIN_LOCATION } from 'config/config';

const REPORT_STATES = {
	NOT_STARTED: 'REPORT_NOT_STARTED',
	FAILED: 'REPORT_FAILED',
	NEED_VALIDATION: 'REPORT_NEED_VALIDATION',
	DONE: 'REPORT_DONE',
};

class XBRLReport extends Component {
    state = {
        reportAnyway: false,
        receiptModalOpen: false,
    };

    componentDidMount = () => {
        const { value } = this.props;
        if (value && value.reportStatus === REPORT_STATES.NOT_STARTED) {
            const factId = this.props.options.triggerFact;
            const factData = { number: Date.now() };
            this.props.onChange(factId, factData);
        }
    };

    isWorking = () => {
        return this.props.factsBeingUpdated.has(this.props.options.triggerFact);
    };

    canReportWithAdvices = () => {
        const { reportStatus, token } = this.props.value || {};
        return reportStatus === REPORT_STATES.NEED_VALIDATION && !!token;
    };

    reportXBRL = async () => {
        const factId = this.props.options.triggerFact;
        const factData = { number: Date.now() };
        const updateContext = { report: true };
        this.props.onChange(factId, factData, updateContext);
    };

    getStatusMessage = () => {
        const { value } = this.props;
        if (this.isWorking()) {
            return '';
        }
        switch (value?.reportStatus) {
            case REPORT_STATES.DONE:
                return 'Godkendt';
            case REPORT_STATES.FAILED:
                return 'Fejlet';
            case REPORT_STATES.NEED_VALIDATION:
                return 'Mangler validering';
            default:
                return 'Ikke påbegyndt';
        }
    };

    displayStatusCode = (isStatusOK) => {
        const { value } = this.props;
        if (this.isWorking()) {
            return '';
        }
        // OK
        if (isStatusOK) {
            // vis ingenting her
            return ;
        }

        // Fejlkode når ERST er nede
        if (value?.statusCode === 'erst_api_unavailable') {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
                    <Segment style={{ width: '500px', textAlign: 'left' }} raised>
                        Erhvervsstyrelsens systemløsning er i øjeblikket utilgængeligt. 
                        Der kan gå op til en time før det er muligt at indberette.
                        <br />
                        Prøv venligst igen senere.
                        <br />
                        Hvis problemet fortsætter, bedes du kontakte vores support.
                    </Segment>
                </div>
            );
        }

        // Fejlkode fra ERST
        if (value?.statusCode) {
            let msg = '';
            if (errorMessagesMap.has(value.statusCode)) {
                msg = <span>
                    <strong>Besked:</strong>
                    <span className={styles.leftmargin}>{errorMessagesMap.get(value.statusCode)}</span>                    
                </span>;
            }
            return (<div>
                <span>
                    <strong>Statuskode:</strong>
                    <span className={styles.leftmargin}>{value.statusCode}</span>
                    <Icon name="warning sign" color="yellow" className={styles.leftmargin} />
                </span>
                <br />
                { msg }
            </div>);
        }
        //
        return '';
    };

    renderErstGreeting = () => {
        return <Message positive icon attached='bottom'>
            <Icon name='trophy'/>
            <Message.Content>
                <Message.Header>Årsrapport indberettet</Message.Header>
                Sådan!<br/>
                Din årsrapport er modtaget og godkendt af Erhvervsstyrelsen.
                {this.renderReceiptOpener()}
            </Message.Content>
        </Message>;
    }

    asLink = (displayname, url) => {
        return <a
        style={{ textDecoration: "underline"}}
        href={url}
        target='_blank'
        rel='noopener noreferrer'>
            {displayname}
        </a>
    }

    renderAdviceMessage = () => {
        const { value } = this.props;
        if (this.isWorking() || !value?.adviceMarkdown) return null;
        return (
            <Segment>
                <div
                    dangerouslySetInnerHTML={{__html: this.formatAdviceMsg(value?.adviceMarkdown)}}
                    className={styles.adviceMessage}
                />
            </Segment>
        );
    };

    formatAdviceMsg = (markup) => {
        try {
            return markup
            .replace(/FEJL: (\w+):/gi, '<br/><br/><strong>FEJL: $1:</strong>')
            .replace(/ADVIS: (\w+):/gi, '<br/><br/><strong>ADVIS: $1:</strong>');    
        } catch (err) {
            return markup;
        }
    }

    renderReportAnyway = () => {
        if (!this.canReportWithAdvices()) {
            return null;
        }
        return (
            <div>
                <Checkbox
                    label='Jeg har læst ovenstående og ønsker at indberette alligevel'
                    value={this.state.reportAnyway}
                    onClick={(_, { checked }) => this.setState({ reportAnyway: checked })}
                />
            </div>
        );
    };

    renderReportingMessage = () => {
        if (!this.isWorking()) {
            return null;
        }
        return (
            <p>
                Vi har nu sendt rapporten til Erhvervsstyrelsen.<br/>
                Der går typisk 1-2 minutter, mens de validerer dine data.
            </p>
        );
    };

    renderReceiptOpener = () => {
        const { value } = this.props;
        const receiptMarkdown = value?.receiptMarkdown;

        if (!receiptMarkdown) {
            return null;
        }

        return (
            <span>
                &nbsp;
                <br />
                <ColoredText
                    iconPosition='left'
                    icon='download'
                    onClick={() => this.setState({ receiptModalOpen: true })}
                    content='Hent kvittering fra Erhvervsstyrelsen'
                    link
                />
            </span>
        );
    };

    renderReceiptModal = () => {
        const receiptMarkdown = this.props.value?.receiptMarkdown;
        const closeModal = () => this.setState({ receiptModalOpen: false });
    
        return (
            <Modal open={this.state.receiptModalOpen} onClose={closeModal}>
                <Modal.Header>Kvittering fra Erhvervsstyrelsen</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div dangerouslySetInnerHTML={{ __html: receiptMarkdown }} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal}>
                        Luk
                    </Button>
                    <Button
                        color='black'
                        content='Download som PDF'
                        labelPosition='right'
                        icon='download'
                        onClick={() => {
                            const container = document.createElement('div');
                            container.innerHTML = receiptMarkdown;
                            triggerPrint(container);
                        }}
                    />
                </Modal.Actions>
            </Modal>
        );
    };

    getReportButtonPrerequisites = () => {
        const { reportAnyway } = this.state;
        const { values } = this.props;
        const value = this.props.value || {};
        const _isTaxReported = isTaxReported(values);
        const { reportStatus } = value;
        const canReportWithAdvices = this.canReportWithAdvices();
        const isManReviewNeeded = isManualReviewNeeded(values);
        const status = extractStatus(values);
        const hasSignedReported = status.step >= statusesMap.done.step;

        // button is disabled if any of these expressions are true
        return [
            { cond: !hasSignedReported, msgNotOk: "Du mangler at underskrive årsrapporten", msgOk: "Årsrapporten er underskrevet" },
            { cond: !_isTaxReported, msgNotOk: "Du mangler at indberette selskabsskatten på skat.dk", msgOk: "Selskabsskatten er indberettet på skat.dk" },
            { cond: reportStatus === REPORT_STATES.DONE, hidden: true },
            { cond: canReportWithAdvices && !reportAnyway, msgNotOk: "Du mangler at håndtere advarsler fra Erhvervsstyrelsen", msgOk: " Der er ingen ubehandlede advarsler fra Erhvervsstyrelsen" },
            { cond: this.isWorking(), hidden: true },
            { cond: isManReviewNeeded, msgNotOk: "Årsrapporten er ikke bestået Digital Revisors kvalitetskontrol", msgOk: "Årsrapporten har bestået Digital Revisors kvalitetskontrol" },
        ];
    };

    // Vis de betingelser, der skal opfyldes for at knappen enables.
    // Kun hvis knappen er disabled.
    renderPrerequisites = () => {
        if (!this.isReportButtonDisabled()) return ;
        if (this.isWorking()) return ;

        const listItems = this.getReportButtonPrerequisites()
        .filter(prereq => !prereq.hidden) // do not show hidden prerequisites
        .map(prereq => {
            return <List.Item key={prereq.msg}>
                <Icon name={!prereq.cond ? "check" : "warning"} color={!prereq.cond ? "green" : "black"} />
                { prereq.cond ? prereq.msgNotOk : prereq.msgOk }
            </List.Item>
        })
        return <Segment compact textAlign='left' className={styles.prerequisitesBox}>
            <Header
                size='small'
                content='Forudsætninger'
                subheader='Følgende betingelser skal være opfyldt før din årsrapport er klar til at blive indberettet'
            />
            <List>{listItems}</List>
        </Segment>
    };

    renderViewXbrlButton = () => {
        if (!isAdmin(this.props.userData)) {
            return null;
        }

        const value = this.props.value || {};
        const { xbrlFileID, supplementaryXbrlFileID } = value;
        if (!xbrlFileID && !supplementaryXbrlFileID) {
            return null;
        }

        return (
            <ColoredText
                content='Se XBRL i admin'
                icon='eye'
                iconPosition='left'
                link={`${ADMIN_LOCATION}/taxonomy?xbrlFileID=${xbrlFileID}&supplementaryXbrlFileID=${supplementaryXbrlFileID}`}
            />
        );
    };

    isReportButtonDisabled = () => {
        const { userData: user } = this.props;
        return [
            this.getReportButtonPrerequisites().map(prereq => prereq.cond).some(x => x),
            isAkademi(user),
        ].some(x => x);
    };

    render () {
        const value = this.props.value || {};
        const { statusCode } = value;
        const isStatusOK = (statusCode === 0 || statusCode === "0");

        return <>
            <Segment attached={isStatusOK} textAlign='center' basic>
                <strong>Indberet til Erhvervsstyrelsen</strong>
                <div className={styles.statusBlock}>
                    <b>Status:</b>
                    <span className={styles.leftmargin}>{this.getStatusMessage()}</span>                     
                    <br/>
                    {this.displayStatusCode(isStatusOK)}
                </div>
                {this.renderViewXbrlButton()}
                {this.renderAdviceMessage()}
                {this.renderReportAnyway()}
                {this.renderReportingMessage()}
                <Button
                    primary
                    content='Indberet'
                    onClick={this.reportXBRL}
                    loading={this.isWorking()}
                    disabled={this.isReportButtonDisabled()}
                />
                { !isStatusOK && this.renderPrerequisites() }
            </Segment>
            { isStatusOK && this.renderErstGreeting() }
            {this.renderReceiptModal()}
            <br/>
        </>;
    }
}

export default withUserData(XBRLReport);
